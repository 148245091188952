import React, {useEffect} from 'react'
import {AgGridReact} from 'ag-grid-react';
import AgGridRowDataSerivce from '../../services/AgGridRowDataService';
import ZNormalizeService from '../../services/ZNormalizeService';
import Heatmap from './Heatmap';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function RenderTimeXR(props) {

    const timex = props.results;

    const gridRef = React.createRef();

    const rowDataObj = AgGridRowDataSerivce.createRowDataArray(timex);
    const columnDefsObj = AgGridRowDataSerivce.createColumnDefsArray(timex.headers);

    const [heatmapData, setHeatmapData] = React.useState(timex);
    const [zNormalizedHeatmapData, setZNormalizedHeatmapData] = React.useState();
    const [normalizeSelection, setNormalizeSelection] = React.useState(false);
    const [zNormalizedRowData, setZNormalizedRowData] = React.useState();

    // eslint-disable-next-line
    const [gridHeight, setGridHeight] = React.useState('normal')

    // eslint-disable-next-line
    const [gridApi, setGridApi] = React.useState(null);

    // eslint-disable-next-line
    const [gridColumnApi, setGridColumnApi] = React.useState(null);

    // eslint-disable-next-line
    const [rowData, setRowData] = React.useState(rowDataObj);

    useEffect(() => {
        if (rowDataObj.length < 10) {
            gridRef.current.style.height = '260px';
        } 
    }, [gridRef, rowDataObj]);

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onNormalizeClick = e => {

        const isNormalizedSelected = !normalizeSelection;
        setNormalizeSelection(!normalizeSelection);

        if (isNormalizedSelected) {
            
            // only calculate on first pass
            if (!zNormalizedRowData) {
                const tZNormalizedData = ZNormalizeService.normalizeForAgGrid(rowDataObj);
                const tZNormalizedHeatmapData = ZNormalizeService.normalizeForHeatmap(timex);
                setRowData(tZNormalizedData);
                setHeatmapData(tZNormalizedHeatmapData);
                setZNormalizedRowData(tZNormalizedData);
                setZNormalizedHeatmapData(tZNormalizedHeatmapData);
            
            // read from cache
            } else {
                setRowData(zNormalizedRowData);
                setHeatmapData(zNormalizedHeatmapData);
            }

        } else {
            setRowData(rowDataObj);
            setHeatmapData(timex);
        }
        
    };

    const onGridDownload = () => {
        const params = { fileName: props.baseFilename + '.csv' };
        if (gridApi)
            gridApi.exportDataAsCsv(params);
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-2 offset-1">
                    {normalizeSelection && 
                        <div className="viewNormalizeButton" onClick={onNormalizeClick}>Switch to Raw Data</div>
                    }
                    {!normalizeSelection && 
                        <div className="viewNormalizeButton" onClick={onNormalizeClick}>Switch to Z-Normalized Data</div>
                    }
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-11">
                    <Heatmap data={heatmapData} baseFilename={props.baseFilename} isNormalizedSelected={normalizeSelection}/>
                </div>
            </div>
            <div className="spacer"/>
            <div className="spacer"/>
            <div className="row justify-content-end">
                <div className="col-3 col-xs-2" onClick={()=>onGridDownload()}>
                    <img src={DownloadSVG} className="downloadIcon" alt="download-csv"/>
                    CSV
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 ag-theme-balham" ref={gridRef} style={{ height: '500px' }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columnDefsObj}
                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                        domLayout={gridHeight}
                    >
                    </AgGridReact>
                </div>
            </div>
            <div className="spacer"/>
        </React.Fragment>
    );
}

export default RenderTimeXR
