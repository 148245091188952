import React, {useEffect} from 'react'
import StateService from '../../services/StateService';
import RenderEstimate from './RenderEstimate';
import RenderGeneCoverage from './RenderGeneCoverage';
import RenderTimeXR from './RenderTimeXR';
import RenderComparativeAnalysis from './RenderComparativeAnalysis';

function ResultsNavbar(props) {

    const firstRenderFlag = 'firstRender';
    const datasetTypes = { datasets: 'datasets', otherAnalyses: 'otherAnalyses' };
    const showTabsIfCustomSignatureUsed = ['purity', 'geneCoverage', 'filteredTimex'];

    const datasets = {
        purity: { name: 'Tumor Purity', ref: React.createRef() },
        geneCoverage: { name: 'Signature Coverage', ref: React.createRef() },
        filteredTimex: { name: 'TIMEx Score', ref: React.createRef() },
        filteredTimexScaled: { name: 'TIMEx Linear', ref: React.createRef() },
        otherAnalyses: { name: 'Other Analyses', ref: React.createRef() },
        comparativeAnalysis: { name: 'Comparative Analysis', ref: React.createRef() }
    };

    const otherAnalyses = {
        cibersort: { name: 'Cibersort', ref: React.createRef() },
        cibersortAbs: { name: 'Cibersort Abs.', ref: React.createRef() },
        consensusTme: { name: 'Consensus TME', ref: React.createRef() },
        epic: { name: 'Epic', ref: React.createRef() },
        mcpCounter: { name: ' MCP-counter', ref: React.createRef() },
        quantiSeq: { name: 'quanTIseq', ref: React.createRef() },
        timer: { name: 'TIMER', ref: React.createRef() },
        xcell: { name: 'xCell', ref: React.createRef() }
    };

    const [dataSelection, setDataSelection] = React.useState(firstRenderFlag);
    const [dataSelectionOther, setDataSelectionOther] = React.useState();
    const [showOtherAnalysesList, setShowOtherAnalysesList] = React.useState(false);
    const [otherAnalysisSelection, setOtherAnalysisSelection] = React.useState();
    const [resultsObj, setResultsObj] = React.useState();
    const [datasetExists, setDatasetExists] = React.useState(true);

    const otherAnalysesBackgroundRef = React.createRef();

    useEffect(() => {
        let subscription = StateService.results$().subscribe(res => {
             setResultsObj(res);
             if (dataSelection === datasets.filteredTimex.name || dataSelection === firstRenderFlag) {
                setDataSelection(datasets.filteredTimex.name);
                if (datasets.filteredTimex.ref.current) 
                    datasets.filteredTimex.ref.current.className = 'resultsNavbarSelected';
             }
        });
 
        return () => {
            subscription.unsubscribe();
        };
     }, [datasets.filteredTimex.name, datasets.filteredTimex.ref, dataSelection])

     useEffect(() => {
        if (otherAnalysesBackgroundRef.current) {
            showOtherAnalysesList ? otherAnalysesBackgroundRef.current.style.display = 'block' : otherAnalysesBackgroundRef.current.style.display = 'none';
        }
     }, [showOtherAnalysesList, otherAnalysesBackgroundRef])

    const onClick = e => {
        if (e === datasets.otherAnalyses.name) {
            setShowOtherAnalysesList(!showOtherAnalysesList);
        } else {
            _checkIfDataExists(e, datasetTypes.datasets);
            setDataSelection(e);
            setDataSelectionOther(null);
            setOtherAnalysisSelection(null);
            if (showOtherAnalysesList) {
                setShowOtherAnalysesList(!showOtherAnalysesList);
            }
        }

        Object.keys(datasets).forEach(key => {
            if (datasets[key].name === e) {
                datasets[key].ref.current.className = 'resultsNavbarSelected';
            } else {
                if (datasets[key].ref.current) {
                    datasets[key].ref.current.className = 'resultsNavbarUnselected';
                }
            }
        });
    };

    const onClickOtherAnalyses = e => {
        const key = e.target.id.replace('other', '');
        _checkIfDataExists(e, datasetTypes.otherAnalyses);
        setOtherAnalysisSelection(otherAnalyses[key].name);
        setShowOtherAnalysesList(!showOtherAnalysesList);
        setDataSelection(null);
        setDataSelectionOther(otherAnalyses[key].name);
    };
    
    const _checkIfDataExists = (name, datasetType) => {
        // NOTE: datasetType === datasets || datasetType === otherAnalyses
        
        if (datasetType === datasetTypes.datasets) {
            Object.keys(datasets).forEach(key => {
                if (datasets[key].name === name) {
                    if (name !== datasets.comparativeAnalysis.name) {
                        if (resultsObj && resultsObj[key]) {
                            setDatasetExists(true);
                        } else {
                            setDatasetExists(false);
                        }
                    }
                    if (name === datasets.comparativeAnalysis.name) {
                        setDatasetExists(true);
                    }
                }
            });
        } else {
            const key = name.target.id.replace('other', '');
            if (resultsObj && resultsObj[key]) {
                setDatasetExists(true);
            } else {
                setDatasetExists(false);
            }
        }

    };

    return (
        <React.Fragment>
            <div className="spacer"/>
            <div className="row justify-content-center">
                {
                    Object.keys(datasets).map((key, i) => {
                        if (props.customSignatureUsed && showTabsIfCustomSignatureUsed.includes(key)) {
                            return (
                                <div className={"navbarResults optionResults navbarResults" + String(i)} id={'resNavBar' + datasets[key].name} key={i} onClick={()=>onClick(datasets[key].name)}>
                                    <span ref={datasets[key].ref}>{datasets[key].name}</span>
                                </div>
                            )
                        }

                        if (!props.customSignatureUsed) {
                            return (
                                <div className={"navbarResults optionResults navbarResults" + String(i)} id={'resNavBar' + datasets[key].name} key={i} onClick={()=>onClick(datasets[key].name)}>
                                    <span ref={datasets[key].ref}>{datasets[key].name}</span>
                                </div>
                            )
                        }
                        
                        return null;
                    })
                }
            </div>
            <div className="row justify-content-center">
                <div className="offset-4 otherAnalysesBackground" ref={otherAnalysesBackgroundRef}style={{position: 'absolute', zIndex: 20}}>
                    {showOtherAnalysesList &&
                        Object.keys(otherAnalyses).map((key,i) => {
                            return (
                                <div className="offset-8 otherAnalyses" id={'other' + key} key={i} ref={otherAnalyses[key].ref} onClick={onClickOtherAnalyses}>{otherAnalyses[key].name}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="spacer"/>
            <div className="row">
                <div className="col-6 offset-1">
                    Selected View:
                    {dataSelection &&
                        <span> {dataSelection}</span>
                    }
                    {dataSelectionOther && 
                        <span> {dataSelectionOther}</span>
                    }
                </div>
            </div>
            <div className="spacer"/>
            {dataSelection === datasets.purity.name && datasetExists  &&
                <RenderEstimate results={resultsObj.purity} baseFilename={'tumor_purity'}/>
            }
            {dataSelection === datasets.geneCoverage.name && datasetExists  &&
                <RenderGeneCoverage results={resultsObj.geneCoverage} baseFilename={'signature_purity'}/>
            }
            {dataSelection === datasets.filteredTimex.name && datasetExists &&
                <RenderTimeXR results={resultsObj.filteredTimex} baseFilename={'timex_score'} style={{zIndex: 1}}/>
            }
            {dataSelection === datasets.filteredTimexScaled.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.filteredTimexScaled} baseFilename={'timex_proportion'}/>
            }
            {dataSelection === datasets.comparativeAnalysis.name && datasetExists && !props.customSignatureUsed &&
                <RenderComparativeAnalysis jobId={props.jobId}/>
            }
            {otherAnalysisSelection === otherAnalyses.cibersort.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.cibersort} baseFilename={'cibersort'}/>
            }
            {otherAnalysisSelection === otherAnalyses.cibersortAbs.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.cibersortAbs} baseFilename={'cibersort_abs'}/>
            }
            {otherAnalysisSelection === otherAnalyses.consensusTme.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.consensusTme} baseFilename={'consensus_tme'}/>
            }
            {otherAnalysisSelection === otherAnalyses.epic.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.epic} baseFilename={'epic'}/>
            }
            {otherAnalysisSelection === otherAnalyses.mcpCounter.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.mcpCounter} baseFilename={'mcp_counter'}/>
            }
            {otherAnalysisSelection === otherAnalyses.quantiSeq.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.quantiSeq} baseFilename={'quanti_seq'}/>
            }
            {otherAnalysisSelection === otherAnalyses.timer.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.timer} baseFilename={'timer'}/>
            }
            {otherAnalysisSelection === otherAnalyses.xcell.name && datasetExists && !props.customSignatureUsed &&
                <RenderTimeXR results={resultsObj.xcell} baseFilename={'xcel'}/>
            }
            {!datasetExists &&
                <div className="row justify-content-center">
                    <div className="col-6 error" style={{textAlign: 'center'}}>This dataset is unavailable for viewing</div>
                </div>
            }
        </React.Fragment>
    )
}

export default ResultsNavbar
