import React from 'react'
import CancerTypes from './about/CancerTypes';
import CellTypes from './about/CellTypes';
import DownloadSVG from '../resources/svg/file-download-solid.svg';
import CellTypesDifEst from './about/CellTypesDifEst';

function About() {

    const USER_MANUAL_URL = process.env.REACT_APP_PROXY_HOST + ':' + process.env.REACT_APP_PROXY_PORT + '/manual/' + process.env.REACT_APP_USER_MANUAL_FILENAME;
    const TIMEX_SIGNATURES_URL = process.env.REACT_APP_PROXY_HOST + ':' + process.env.REACT_APP_PROXY_PORT + '/manual/' + process.env.REACT_APP_USER_TIMEX_SIGNATURES;
    const TIMEX_SIGNATURE_GMT_URL = process.env.REACT_APP_PROXY_HOST + ':' + process.env.REACT_APP_PROXY_PORT + '/manual/' + process.env.REACT_APP_USER_TIMEX_SIGNATURE_GMT;

    const onClickNewTab = () => {
        const url = 'https://software.broadinstitute.org/cancer/software/gsea/wiki/index.php/Data_formats#GMT:_Gene_Matrix_Transposed_file_format_.28.2A.gmt.29';
        window.open(url, '_blank');
    };

    return (
        <React.Fragment>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8">
                    <h4>Help</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 sectionDivider"/>
            </div>
            <div className="row justify-content-center">
                <div className="col-8" style={{textAlign: 'center'}}>
                <a href={USER_MANUAL_URL}><img src={DownloadSVG} className="downloadIcon" alt="download-csv"/><u>Download User Manual</u></a>
                </div>
            </div>
            <div className="spacer"/>
            <div className="row justify-content-center">
                <div className="col-8">
                    <h4>TIMEx Citation</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 sectionDivider"/>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 offset-1">
                We would be very grateful if you cite TIMEx in your work.
                </div>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8 offset-1">
                    Xie M, Lee K, Lockhart JH, Cukras SD, Carvajal R, Beg AA, Flores ER, Teng M, Chung CH, Tan AC. (2021). TIMEx: tumor-immune microenvironment deconvolution web-portal for bulk transcriptomics using pan-cancer scRNA-seq signatures. Bioinformatics, 37(20): 3681–3683. <a href="https://doi.org/10.1093/bioinformatics/btab244" target="_blank" rel="noopener noreferrer">https://doi.org/10.1093/bioinformatics/btab244</a> [PMID: <a href="https://pubmed.ncbi.nlm.nih.gov/33901274/" target="_blank" rel="noopener noreferrer">33901274</a>]
                </div>
            </div>
            <div className="spacer"/>
            <div className="row justify-content-center">
                <div className="col-8">
                    <h4>Cancer Types</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 sectionDivider"/>
            </div>
            <div className="row">
                <div className="col-12">
                    <CancerTypes/>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8">
                    <h4>Cell Types</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 sectionDivider"/>
            </div>
            <div className="row">
                <div className="col-12">
                    <CellTypes/>
                </div>
            </div>
            <div className="spacerLg"/>
            <div className="row justify-content-center">
                <div className="col-8">
                    <h4>TIMEx Signatures</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 sectionDivider"/>
            </div>
            <div className="row justify-content-center">
                <div className="col-8" style={{textAlign: 'center'}}>
                    <a href={TIMEX_SIGNATURES_URL}><img src={DownloadSVG} className="downloadIcon" alt="download-csv"/><u>Download TIMEx Signatures</u> (.csv)</a>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8" style={{textAlign: 'center'}}>
                    <a href={TIMEX_SIGNATURE_GMT_URL}><img src={DownloadSVG} className="downloadIcon" alt="download-gmt"/><u>Download TIMEx Signatures</u> (.gmt)</a>
                </div>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8" style={{textAlign: 'center'}}>
                    For information about generating .gmt files, please see this <span className="falseAnchor" onClick={onClickNewTab}>link</span>
                </div>
            </div>
            <div className="spacerLg"/>
            <div className="row justify-content-center">
                <div className="col-8">
                    <h4>Different Cell Types Estimated by TIMEx and Other Methods</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 sectionDivider"/>
            </div>
            <div className="row justify-content-center">
                <div className="col-8 scrollTableNotice">
                    Please scroll table right to view all contents
                </div>
            </div>
            <div className="spacer"/>
            <div className="row">
                <div className="col-12">
                    <CellTypesDifEst/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default About
