import React from 'react'
import {AgGridReact} from 'ag-grid-react';
import cellTypes from '../../resources/json/cellTypes.json';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function CellTypes() {

    const rowData = () => {
        const output = [];

        cellTypes.forEach(obj => {
            output.push({ abbreviation: obj.abbreviation, cellType: obj.cellType, lineage: obj.lineage, category: obj.category, numberOfGenes: obj.numberOfGenes });
        });
        
        return output;
    };

    const columnDefs = () => {
        return [
            { headerName: 'Abbreviation', field: 'abbreviation', filter: 'agTextColumnFilter', width: 150, cellStyle: { 'font-size': '12px' } },
            { headerName: 'Cell Type', field: 'cellType', filter: 'agTextColumnFilter', width: 250, cellStyle: { 'font-size': '12px' } },
            { headerName: 'Lineage', field: 'lineage', filter: 'agTextColumnFilter', width: 80, cellStyle: { 'font-size': '12px' } },
            { headerName: 'Category', field: 'category', filter: 'agTextColumnFilter', width: 80, cellStyle: { 'font-size': '12px' } },            
            { headerName: 'Number of Genes', field: 'numberOfGenes', filter: 'agNumberColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } }
        ]
    };

    // eslint-disable-next-line
    const [gridApi, setGridApi] = React.useState(null);
    // eslint-disable-next-line
    const [gridColumnApi, setGridColumnApi] = React.useState(null);

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    const onGridDownload = () => {
        const params = { fileName: 'cell_types.csv' };
        if (gridApi)
            gridApi.exportDataAsCsv(params);
    };

    return (
        <React.Fragment>
            <div className="row justify-content-end">
                <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" onClick={()=>onGridDownload()}>
                    <img src={DownloadSVG} className="downloadIcon" alt="download-csv"/>
                    <span style={{cursor: 'pointer'}}>CSV</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-xs-8 ag-theme-balham">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData()}
                        columnDefs={columnDefs()}
                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                        domLayout={'autoHeight'}
                    >
                    </AgGridReact>
                </div>
            </div>
            <div className="spacer"/>
        </React.Fragment>
    )
}

export default CellTypes
