import React, { useEffect } from 'react'
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import JobSubmissionNotification from './JobSubmissionNotification';
import GoToResultsButton from './GoToResultsButton';
import mockStatusUpdates from '../../resources/json/mockStatusUpdates.json';
import mockStatusUpdatesCustomGmt from '../../resources/json/mockStatusUpdatesCustomGmt.json';

function ExampleUploadPanelClientDriven(props) {

    const [status, setStatus] = React.useState('Preparing data');
    const [goToResults, setGoToResults] = React.useState(false);

    const loadingBarRef = React.useRef();

    useEffect(() => {

        let loadingBarIncrementBy = 6.25;
        let percentComplete = 0;

        if (props.customSignature) {
            loadingBarIncrementBy = 20;
        }

        timer(500, 2000).pipe(take(Math.ceil(100/loadingBarIncrementBy)))
            .subscribe(i => {
                const x = ++i;
                const nextValue = Math.ceil(loadingBarIncrementBy * x);
                nextValue > 100 ? percentComplete = 100 : percentComplete = nextValue;
                if (!props.customSignature) {
                    setStatus(mockStatusUpdates.statuses[i]);
                } else {
                    setStatus(mockStatusUpdatesCustomGmt.statuses[i])
                }
                document.getElementById('loadingBar1').style.width = percentComplete + '%';

                if (nextValue >= 100) {
                    setGoToResults(true);
                }
            });

    }, [props.jobId, props.customSignature]);

    return (
        <React.Fragment>
            <p/>
            <JobSubmissionNotification jobId={props.jobId} email={props.email} anonymousEmail={props.anonymousEmail}/>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8 col-xl-4 customBorder">
                    <div className="row justify-content-ceneter">
                        <div className="col-12">
                            Current Status: <span className="affirmative">{status}</span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="loadingBarBorder">
                                <div id="loadingBar1" className="loadingBar" ref={loadingBarRef}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {goToResults &&
                <GoToResultsButton jobId={props.jobId}/>
            }
        </React.Fragment>
    )
}

export default ExampleUploadPanelClientDriven
