import React, { useEffect } from 'react';
import {BehaviorSubject} from 'rxjs';
import UploadInstructions from './upload/UploadInstructions';
import TcgaNotice from './upload/TcgaNotice';
import JobSubmissionNotification from './upload/JobSubmissionNotification';
import FileUploadCompletion from './upload/FileUploadCompletion';
import StatusUpdates from './upload/StatusUpdates';
import StatusUpdatesClientDriven from './upload/StatusUpdatesClientDriven';
import ExampleUploadPanel from './upload/ExampleUploadPanel';
import ExampleUploadPanelClientDriven from './upload/ExampleUploadPanelClientDriven';
import AdvancedOptions from './upload/AdvancedOptions';
import HttpService from '../services/HttpService';
import cancerTypes from '../resources/json/cancerTypes.json';
import routes from '../resources/json/routes.json';
import warnings from '../resources/json/warnings.json';
//import HumanSVG from '../resources/svg/human.svg';
//import MouseSVG from '../resources/svg/mouse.svg';

function Upload() {

    //const species = { human: 'Human', mouse: 'Mouse' };
    const selectFileText = 'Select File...';
    const ANONYMOUS_EMAIL = 'anonymous';
    const MAX_UPLOAD_SIZE_BYTES = 52428800;
    const FILE_SIZE_WARNING_BYTES = 26214400;
    const GENE_PROFILE_LENGTH_THRESHOLD = 5000;
    const ACCEPTED_FILE_TYPES_INPUT_FIELD = ".csv,.tsv,.txt";

    const acceptedFileTypes = [
        'text/csv',
        'text/plain',
        'text/tab-separated-values'
    ];

    const dropzoneMessages = {
        standard: 'Drop Expression Profile Here',
        acceptedFTypes: ['.csv', '.tsv', '.txt (tab separated)'],
        unsupportedFileType: 'Unsupported File Type',
        exceedsMaxFileSize: 'File size exceeds ' + MAX_UPLOAD_SIZE_BYTES + ' bytes'
    }

    const dropzoneCssIds = {
        standard: 'dropboxUploadStandard',
        error: 'dropboxUploadError'
    };

    const cssHexCodes = {
        borderSuccess: '#63d4c2',
        borderFailure: '#f6003a',
        borderStandard: '#06225c'
    }

    const instructions = {
        instruction: { show: 'Show Instructions', hide: 'Hide Instructions' },
        tcgaNotice: { show: 'TCGA Notice', hide: 'Hide TCGA Notice' }
    }

    const [selectedFile, setSelectedFile] = React.useState(selectFileText);
    const [uploadedData, setUploadedData] = React.useState();
    //const [selectedSpecies, setSelectedSpecies] = React.useState(species.human);
    const [selectedCancerType, setSelectedCancerType] = React.useState('ACC');
    const [notifyAfterDispatch, setNotifyAfterDispatch] = React.useState(false);
    const [jobId, setJobId] = React.useState();
    const [jobIdError, setJobIdError] = React.useState(false);
    const [jobExists, setJobExists] = React.useState({ err: false, jobExists: false, jobId: '', processingComplete: false });
    const [isMicroarrayData, setIsMicroarrayData] = React.useState(true);
    const [email, setEmail] = React.useState(ANONYMOUS_EMAIL);
    const [emailError, setEmailError] = React.useState(false);
    const [dropzoneMessage, setDropzoneMessage] = React.useState(dropzoneMessages.standard);
    const [uploadExampleData, setUploadExampleData] = React.useState(false);
    const [submitExample, setSubmitExample] = React.useState(false);
    const [showFileSizeWarning, setShowFileSizeWarning] = React.useState(false);
    const [instructionButtonText, setInstructionButtonText] = React.useState(instructions.instruction.show);
    const [tcgaNoticeButtonText, setTcgaNoticeButtonText] = React.useState(instructions.tcgaNotice.show);
    const [showDuplicateGeneWarning, setShowDuplicateGeneWarning] = React.useState(false);
    const [showNegativeNumberWarning, setShowNegativeNumberWarning] = React.useState(false);
    const [fileUploadPercentComplete, setFileUploadPercentComplete] = React.useState(0);
    const [showFileUploadProgressBar, setShowFileUploadProgressBar] = React.useState(false);
    const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false);
    const [customSignature, setCustomSignature] = React.useState();
    const [customGmtValid, setCustomGmtValid] = React.useState(true);
    
    const dropzoneRef = React.createRef();
    const microarrayRef = React.createRef();
    const rnaseqRef = React.createRef();
    const emailRef = React.createRef();
    const jobidRef = React.createRef();
    const cancerTypeRef = React.createRef();

    const customGmtSubject = new BehaviorSubject();
    customGmtSubject.subscribe(res => {
        if (res !== undefined) {
            if (res === null) {
                _resetBorder();
                _resetState();
            }
            setCustomSignature(res);
        }
    });

    const customGmtValidSubject = new BehaviorSubject();
    customGmtValidSubject.subscribe(res => {
        if (res !== null && res !== undefined && res !== '') {
            setCustomGmtValid(res);
        }
    });

    const exampleGmtLoadSubject = new BehaviorSubject();
    exampleGmtLoadSubject.subscribe(res => {
        if (res !== undefined && res !== null) {
            setCustomSignature(true);
            onLoadExample(true)
        }
    });

    useEffect(() => {
        setShowFileUploadProgressBar(false);
    }, [notifyAfterDispatch]);

    const onDrop = e => {
        e.preventDefault();
        _resetBorder();
        _resetState();

        if (e.dataTransfer.items[0] && e.dataTransfer.items[0].kind === 'file') {    
            const file = e.dataTransfer.items[0].getAsFile();
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8'); 
            const fileMeta = { filename: file.name, type: file.type, size: file.size };
            setSelectedFile(fileMeta.filename)

            if (acceptedFileTypes.includes(file.type)) {
                if (e.dataTransfer.files[0].size <= MAX_UPLOAD_SIZE_BYTES) {
                        
                    _checkFileSizeWarning(e.dataTransfer.files[0].size);
                    
                    dropzoneRef.current.style.borderColor = cssHexCodes.borderSuccess;
                    reader.onload = function (evt) {
                        const rawDataString = evt.target.result;
                        const profileValidationErr = _validateGeneProfile(rawDataString)
                        if (!profileValidationErr.error) {
                            _setUploadedData(rawDataString, fileMeta);
                        } else {
                            document.getElementById('dropboxUploadStandard').style.borderColor = cssHexCodes.borderFailure;
                            setDropzoneMessage(profileValidationErr.message);
                            document.getElementById('dropboxUploadStandard').id = dropzoneCssIds.error;
                        }
                    }
                } else {
                    _setFailCss(dropzoneMessages.exceedsMaxFileSize);
                }
            } else {
                _setFailCss(dropzoneMessages.unsupportedFileType);
            }
        }
    };

    const onDragOver = e => {
        e.preventDefault();
        dropzoneRef.current.id = dropzoneCssIds.standard; 
        dropzoneRef.current.style.borderColor = '#4cafea';
        setDropzoneMessage(dropzoneMessages.standard);
        setSelectedFile(selectFileText);
        setUploadedData();
    };

    const onDragLeave = e => {
        e.preventDefault();
        _resetBorder();
    };

    const _resetBorder = () => { dropzoneRef.current.style.borderColor = cssHexCodes.borderStandard };

    const onManualFileUpload = e => {

        _resetBorder();
        _resetState();

        const file = e.target.files[0];
        const fileMeta = { filename: file.name, type: file.type, size: file.size };
        setSelectedFile(fileMeta.filename);

        if (acceptedFileTypes.includes(e.target.files[0].type)) {

            if (e.target.files[0].size <= MAX_UPLOAD_SIZE_BYTES) {

                _checkFileSizeWarning(e.target.files[0].size);

                dropzoneRef.current.style.borderColor = cssHexCodes.borderSuccess;
                dropzoneRef.current.id = dropzoneCssIds.standard;
                setDropzoneMessage(dropzoneMessages.standard);

                const reader = new FileReader()
                reader.onload = file => {
                    const rawDataString = file.target.result;
                    const profileValidationErr = _validateGeneProfile(rawDataString);
                    if (!profileValidationErr.error) {
                        _setUploadedData(rawDataString, fileMeta);
                    } else {
                        document.getElementById('dropboxUploadStandard').style.borderColor = cssHexCodes.borderFailure;
                        setDropzoneMessage(profileValidationErr.message);
                        document.getElementById('dropboxUploadStandard').id = dropzoneCssIds.error;
                    }
                };
                reader.readAsText(e.target.files[0]);
            } else {
                _setFailCss(dropzoneMessages.exceedsMaxFileSize);
            }

        } else {
            _setFailCss(dropzoneMessages.unsupportedFileType);
        }
    };

    const _setFailCss = errorMessage => {
        dropzoneRef.current.style.borderColor = cssHexCodes.borderFailure;
        setDropzoneMessage(errorMessage);
        dropzoneRef.current.id = dropzoneCssIds.error;
    };

    const _setUploadedData = (rawDataString, fileMeta) => {
        setUploadedData({ rawDataString, fileMeta });
    };

    //const onSpeciesSelect = e => {
    //    setSelectedSpecies(e.target.name);
    //};

    const onChangeCancerType = e => {
        let ary = e.target.value.split('(');
        ary = ary[1].split(')');
        setSelectedCancerType(ary[0]);
    };

    const onSelectModality = e => {
        if (e === 'microarrayM') {
            microarrayRef.current.className = 'experimentModalitySelected';
            rnaseqRef.current.className = 'experimentModality';
            setIsMicroarrayData(true);
        }

        if (e === 'rnaseqM') {
            microarrayRef.current.className = 'experimentModality';
            rnaseqRef.current.className = 'experimentModalitySelected';
            setIsMicroarrayData(false);
        }
    };

    const _bytesToMb = () => {
        return MAX_UPLOAD_SIZE_BYTES / 1048576
    };

    const onSubmit = () => {
        if (jobId !== '' && jobId !== null && jobId !== undefined) {

            const tJobId = jobId + _createTimestamp();
            setJobId(tJobId);

            const isEmailValid = _validateEmail(true);
            if (isEmailValid) {
                _resetState();
                let body;
                showAdvancedOptions && customSignature ?
                    body = { ...uploadedData, customSignature, /*species: selectedSpecies,*/ cancerType: selectedCancerType, email: email,  isMicroarray: isMicroarrayData, jobId: tJobId, customSignatureUsed: true } :
                    body = { ...uploadedData, /*species: selectedSpecies,*/ cancerType: selectedCancerType, email: email,  isMicroarray: isMicroarrayData, jobId: tJobId, customSignatureUsed: false };

                const url = routes.server.api.root + routes.server.api.compute;
                const config = { 
                    onUploadProgress: progressEvent => {
                        const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setFileUploadPercentComplete(percentComplete);
                        setShowFileUploadProgressBar(true);
                    }
                };
                
                HttpService.put(url, body, config).then(req => {
                    if (req.data.jobExists) {
                        setJobExists({
                            err: req.data.err,
                            jobExists: req.data.jobExists,
                            jobId: req.data.jobId,
                            processingComplete: req.data.processingComplete
                        });
                    } else {
                        setNotifyAfterDispatch(true);
                    }
                });

            } else {
                setEmailError(true);
            }

        } else {
            setJobIdError(true);
        }
    };

    const _convertJobStatusToString = () => {
        if (jobExists.processingComplete) {
            return 'Job complete. Data available for viewing';
        } 
        
        return 'Job is currently being processed';
    };

    const _resetState = () => {
        setJobExists({ err: false, jobExists: false, jobId: '', processingComplete: false });
        setEmail(ANONYMOUS_EMAIL);
        setNotifyAfterDispatch(false);
        setUploadExampleData(false);
        setSubmitExample(false);
        setShowFileSizeWarning(false);
        setUploadedData();
        setJobIdError(false);
        setShowDuplicateGeneWarning(false);
        setShowNegativeNumberWarning(false);
        setFileUploadPercentComplete(0);
        setShowFileUploadProgressBar(false);
        jobidRef.current.disabled = false;
        emailRef.current.disabled = false;
        cancerTypeRef.current.disabled = false;
    };

    const onChange = e => {
        setEmailError(false);
        setNotifyAfterDispatch(false);

        if (e.target.id === 'jobidLanding') {
            setJobIdError(false);
            setJobId(e.target.value);
        } else {
            let tEmail = e.target.value;
            if (e.target.value === '') {
                tEmail = ANONYMOUS_EMAIL;
            }
            setEmail(tEmail);
        }
    };

    const onLoadExample = (bLoadExampleGmt) => {
        _resetBorder();
        _resetState();
        emailRef.current.disabled = true;
        document.getElementById('dropboxUploadStandard').id = dropzoneCssIds.standard;
        let EXAMPLE_JOBID = 'LUAD_EXAMPLE';

        if (bLoadExampleGmt) {
            EXAMPLE_JOBID = 'LUAD_EXAMPLE_CSTM_GMT';
        } else {
            setShowAdvancedOptions(false);
            setCustomSignature(null);
        }

        jobidRef.current.value = EXAMPLE_JOBID;
        jobidRef.current.disabled = true;
        for (let i = 0; i < cancerTypeRef.current.children.length; i++) {
            if (cancerTypeRef.current.children[i].label === 'LUAD') {
                cancerTypeRef.current.selectedIndex = i;
            }
        }
        cancerTypeRef.current.disabled = true;
        onSelectModality('rnaseqM');
        setJobId(EXAMPLE_JOBID);
        setUploadExampleData(true);
    };

    const onDownloadExample = () => {
        HttpService.get(routes.server.api.root + routes.server.api.exampleGet)
            .then(res => {
                const csvFile = new Blob([res.data.exampleDataset], { type: 'text/csv' })
                const downloadLink =  document.createElement('a')
                downloadLink.download = `example_dataset_input.csv`
                downloadLink.href = window.URL.createObjectURL(csvFile)
                downloadLink.style.display = 'none'
                document.body.appendChild(downloadLink)
                downloadLink.click()
            });
    };

    const onSubmitExample = () => {
        if (_validateEmail()) {
            setSubmitExample(true);
        }
    };

    const _validateEmail = () => {
        if (email !== ANONYMOUS_EMAIL && !email.includes('@')) {
            setEmailError(true);
            return false;
        }

        return true;
    };

    const _validateGeneProfile = (data) => {
        let isAlphaDetected = false;
        const errors = [];
        const geneList = [];
        const regex = RegExp('[a-zA-Z]', 'g');
        const negativeNumberRegex = RegExp('\\s-{1}\\d*\\.{0,1}\\d+', 'g');

        const aryData = data.split('\n');
        aryData.shift();

        for (const row of aryData) {
            const gene = row.split('\t')[0];
            geneList.push(gene);
            if (regex.test(gene)) {
                isAlphaDetected = true;
            }
        }

        if (!isAlphaDetected) {
            errors.push({ error: true, message: 'Unsupported gene names' });
        }

        if (aryData.length < GENE_PROFILE_LENGTH_THRESHOLD) {
            errors.push({ error: true, message: 'The input file is incomplete, please upload the full gene expression profiles. Number of genes must exceed ' + GENE_PROFILE_LENGTH_THRESHOLD });
        }

        for (const obj of errors) {
            if (obj.error) {
                return obj;
            }
        }

        negativeNumberRegex.test(data) ? setShowNegativeNumberWarning(true) : setShowNegativeNumberWarning(false);
        (new Set(geneList)).size !== geneList.length ? setShowDuplicateGeneWarning(true) : setShowDuplicateGeneWarning(false)
        
        return { error: false, message: null };
    };

    const _checkFileSizeWarning = fileSize => {
        fileSize > FILE_SIZE_WARNING_BYTES ? setShowFileSizeWarning(true) : setShowFileSizeWarning(false);
    };

    const _createTimestamp = () => {
        let month, day, hour, minute, second;
        const date = new Date();

        date.getMonth()+1 < 10 ? month = '0'+(date.getMonth()+1) : month = date.getMonth()+1;
        date.getDate() < 10 ? day = '0'+date.getDate() : day = date.getDate();
        date.getHours() < 10 ? hour = '0'+date.getHours() : hour = date.getHours();
        date.getMinutes() < 10 ? minute = '0'+date.getMinutes() : minute = date.getMinutes();
        date.getSeconds() < 10 ? second = '0'+date.getSeconds() : second = date.getSeconds();
        
        return '_' + date.getFullYear() + month + day + '_' + hour + minute + second;
    };

    const toggleInstructions = () => {
        instructionButtonText === instructions.instruction.show ?
            setInstructionButtonText(instructions.instruction.hide) :
            setInstructionButtonText(instructions.instruction.show);
    }

    const toggleTcgaNotice = () => {
        tcgaNoticeButtonText === instructions.tcgaNotice.show ? 
            setTcgaNoticeButtonText(instructions.tcgaNotice.hide) :
            setTcgaNoticeButtonText(instructions.tcgaNotice.show);
    }

    const onClickAdvancedOptions = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    return (
        <React.Fragment>
            <p/>
            <div className="row justify-content-center">
                <h3>Welcome to TIMEx</h3>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-1x showInstructionsButton">
                    <div className="instructionsButton" onClick={toggleInstructions}>{instructionButtonText}</div>
                </div>
                <div className="col-1x showTcgaButton" style={{marginLeft: '1em'}}>
                    <div className="instructionsButton" onClick={toggleTcgaNotice}>{tcgaNoticeButtonText}</div>
                </div>
                <div className="col-12">
                {instructionButtonText === instructions.instruction.hide &&
                    <UploadInstructions/>
                }
                {tcgaNoticeButtonText === instructions.tcgaNotice.hide &&
                    <TcgaNotice/>
                }
                </div>
            </div>
            <p/>
            {jobIdError &&
                <React.Fragment>
                    <p/>
                    <div className="row justify-content-center">
                        <div className="col-xs-10 col-sm-5 offset-1 error">
                            Please enter a Job Name
                        </div>
                    </div>
                </React.Fragment>
            }
            <div className="row justify-content-center">
                <div className="col-10 col-sm-11 col-md-2 col-lg-2 col-xl-1 col-1x inputFieldLabels">
                    <div className="inputFieldText"><b>Job Name</b></div>
                </div>
                <input type="text" className="col-10 col-sm-11 col-md-4" id="jobidLanding" ref={jobidRef} placeholder="Required" onChange={onChange}/>
            </div>
            <p/>
            {emailError &&
                <React.Fragment>
                    <p/>
                    <div className="row justify-content-center">
                        <div className="col-xs-10 col-sm-5 offset-1 error">
                            Invalid email entered
                        </div>
                    </div>
                </React.Fragment>
            }
            <div className="row justify-content-center">
                <div className="col-10 col-sm-11 col-md-2 col-lg-2 col-xl-1 col-1x inputFieldLabels">
                    <div className="inputFieldText"><b>Email</b></div>
                </div>
                <input type="email" className="col-10 col-sm-10 col-md-4" id="emailLanding" ref={emailRef} placeholder="Optional" onChange={onChange}/>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-11 col-sm-11 col-md-8 customBorder">
                    <div className="row justify-content-center">
                        <div className="col-6">
                            <p/>
                            <div className="row justify-content-center">
                                <div className="col-11 " style={{textAlign: 'center'}}>
                                    <b>Select Experiment Modality:</b>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-6 col-xl-3" style={{textAlign: 'center'}}>
                                    <span className="experimentModalitySelected" id="microarrayM" ref={microarrayRef} onClick={()=>onSelectModality('microarrayM')}><b>Microarray</b></span> 
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-3" style={{textAlign: 'center'}}>
                                    <span className="experimentModality" id="rnaseqM" ref={rnaseqRef} onClick={()=>onSelectModality('rnaseqM')}><b>RNAseq</b></span>
                                </div>
                            </div>
                            <p/>   
                            {/*
                            <div className="row justify-content-center">
                                <div className="col-11" style={{textAlign: 'center'}}>
                                    <h6>Selected species: <span id="selectedSpecies">{selectedSpecies}</span></h6>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-2">
                                    <img className="speciesSvg" src={HumanSVG} alt="human.svg" onClick={onSpeciesSelect} name={species.human}></img>
                                </div>
                                <div className="col-2">
                                    <img className="speciesSvg" src={MouseSVG} alt="mouse.svg" onClick={onSpeciesSelect} name={species.mouse}></img>
                                </div>
                            </div>
                            <p/>
                            */}
                            <div className="row justify-content-center">
                                <div className="form-group col-sm-12 col-md-11" style={{textAlign: 'center'}}>
                                    <label htmlFor="cancerTypeDropdown"><b>Cancer Type:</b></label>
                                    <select className="form-control" id="cancerTypeDropdown" ref={cancerTypeRef} onChange={onChangeCancerType}>
                                        {Object.keys(cancerTypes).map((ctype, i) => {
                                                return <option key={i} name={'s0_' + ctype}>{cancerTypes[ctype]} ({ctype})</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {showDuplicateGeneWarning && !showNegativeNumberWarning &&
                                <React.Fragment>
                                    <p/>
                                    <div className="row justify-content-center">
                                        <div className="col-10 attention2 bold">
                                            {warnings.duplicatGeneNames}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {showNegativeNumberWarning &&
                                <React.Fragment>
                                    <p/>
                                    <div className="row justify-content-center">
                                        <div className="col-10 attention2 bold">
                                            {warnings.negativeNumbers}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {uploadedData && !showNegativeNumberWarning && customGmtValid &&
                                <React.Fragment>
                                    <p/>
                                    <div className="row justify-content-center">
                                        <div className="col-10 submitButton" onClick={onSubmit}><b>Submit</b></div>
                                    </div>
                                </React.Fragment>
                            }
                            {uploadExampleData &&
                                <React.Fragment>
                                    <p/>
                                    <div className="row justify-content-center">
                                        <div className="col-10 submitButton" onClick={onSubmitExample}><b>Submit</b></div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="custom-file col-xs-8 col-md-11" id="customFile" lang="es" onChange={onManualFileUpload}>
                                    <input type="file" className="custom-file-input" id="inputFile" aria-describedby="fileHelp" accept={ACCEPTED_FILE_TYPES_INPUT_FIELD}/>
                                    <label className="custom-file-label" id="inputFileLabel" htmlFor="inputFile">{selectedFile}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-11 col-xs-11 col-sm-11 col-md-11 col-lg-6 col-xl-6" onClick={()=>onLoadExample(false)}>
                                    <span id="loadExampleData">Load Example</span>
                                </div>
                                <div className="col-11 col-xs-11 col-sm-11 col-md-11 col-lg-6 col-xl-6 downloadExampleTxtAlign" onClick={onDownloadExample}>
                                    <span id="downloadExampleData">Download Example</span>
                                </div>
                            </div>
                            <p/>
                            <div className="row">
                                <div className="col-12" id="dropboxUploadStandard" ref={dropzoneRef} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
                                    <div className="spacer"/>
                                    {dropzoneMessage}
                                    <div className="spacer"/>
                                    <div className="row">
                                        <div className="col-12">
                                            Accepted File Types:
                                        </div>
                                    </div>
                                    {dropzoneMessages.acceptedFTypes.map((ftype, i) => {
                                        return (
                                            <div className="row" key={i}>
                                                <div className="col-12">
                                                    {ftype}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="spacer"/>
                                    <div className="row">
                                        <div className="col-12">
                                            Max File Size: {_bytesToMb()} MB
                                        </div>
                                    </div>
                                    {showFileSizeWarning && 
                                        <React.Fragment>
                                            <p/>
                                            <div className="row">
                                                <div className="col-12 attention2 bold">
                                                    Large file size detected
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 attention2 bold">
                                                    Please allow time to process
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <p/>
                    <div className="row">
                        <div className="col-xs-12 col-sm-5" id="advancedOptions">
                            <span onClick={onClickAdvancedOptions}>Advanced Options</span>
                        </div>
                    </div>
                    {showAdvancedOptions &&
                        <AdvancedOptions customGmtSubject={customGmtSubject} customGmtValidSubject={customGmtValidSubject} exampleGmtLoadSubject={exampleGmtLoadSubject}/>
                    }
                    <p/>
                    <div className="row justify-content-center">
                        <div className="col-12" style={{textAlign: 'center', fontSize: '0.9em'}}>
                        * Best viewed in the latest version of Chrome (v87+), Firefox (v83+) or Safari (v12+)
                        </div>
                    </div>
                </div>
            </div>
            {jobExists.jobExists &&
                <React.Fragment>
                    <p/>
                    <div className="row justify-content-center">
                        <div className="col-4 customBorder">
                            <div>
                                <span className="attention2">This job has already been dispatched</span>
                            </div>
                            <div>
                                <b>Job Name:</b> <span className="affirmative">{jobExists.jobId}</span>
                            </div>
                            <div>
                                <b>Job Status:</b> {(_convertJobStatusToString())}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
            {showFileUploadProgressBar && !notifyAfterDispatch &&
                <FileUploadCompletion percentComplete={fileUploadPercentComplete}/>
            }
            {notifyAfterDispatch &&
                <React.Fragment>
                    <p/>
                    <JobSubmissionNotification jobId={jobId} email={email} anonymousEmail={ANONYMOUS_EMAIL}/>
                    {process.env.REACT_APP_USE_WEBSOCKETS_PROGRESS_BAR === 'true' &&
                        <StatusUpdates jobId={jobId}/>
                    }
                    {process.env.REACT_APP_USE_WEBSOCKETS_PROGRESS_BAR === 'false' &&
                        <StatusUpdatesClientDriven jobId={jobId}/>
                    }
                </React.Fragment>
            }
            {submitExample && process.env.REACT_APP_USE_WEBSOCKETS_PROGRESS_BAR === 'true' &&
                <ExampleUploadPanel jobId={jobId} email={email} anonymousEmail={ANONYMOUS_EMAIL} customSignature={customSignature}/>
            }
            {submitExample && process.env.REACT_APP_USE_WEBSOCKETS_PROGRESS_BAR === 'false' &&
                <ExampleUploadPanelClientDriven jobId={jobId} email={email} anonymousEmail={ANONYMOUS_EMAIL} customSignature={customSignature}/>
            }
        </React.Fragment>
    )
}

export default Upload
