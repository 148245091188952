import React, { useEffect } from 'react';
import GoToResultsButton from './GoToResultsButton';
import io from 'socket.io-client';

function StatusUpdates(props) {

    const [status, setStatus] = React.useState({ status: 'Preparing data', percentComplete: 0 });
    const [goToResults, setGoToResults] = React.useState(false);
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState();
    const [isProcessingError, setIsProcessingError] = React.useState();

    const loadingBarRef = React.useRef();

    useEffect(() => {

        let URL = null;
        
        if (process.env.REACT_APP_PRODUCTION === 'true') {
            URL = process.env.REACT_APP_STATUS_MS_HOST + '/' + process.env.REACT_APP_STATUS_MS_PROXY_SUFFIX;
        } else {
            URL = process.env.REACT_APP_STATUS_MS_HOST + ':' + process.env.REACT_APP_STATUS_MS_PORT;
        }
        const socket = io(URL, { query: { jobId: props.jobId, route: 'status' } });
    
        socket.on('connect', () => {});
        socket.on('status', res => {

            setStatus({ status: res.runningStatus, percentComplete: res.percentComplete });
            document.getElementById('loadingBar1').style.width = res.percentComplete + '%';

            if (res.processingComplete) {
                socket.disconnect();
                setGoToResults(true);
                setIsTimedOut(res.timedOut);
                setErrorMessage(res.errorMessage);
                setIsProcessingError(res.processingError);
            } 

        });

    }, [props.jobId]);

    useEffect(() => {

        if (isTimedOut || isProcessingError) {
            loadingBarRef.current.className = 'loadingBarError';
        }

    }, [isTimedOut, isProcessingError]);

    return (
        <React.Fragment>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8 col-xl-4 customBorder">
                    <div className="row justify-content-ceneter">
                        <div className="col-12">
                            Current Status: <span className="noticeNormal">{status.status}</span>
                        </div>
                    </div>
                    {(isTimedOut || isProcessingError) &&
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    Error: <span className="noticeError">{errorMessage}</span>
                                </div>
                            </div>
                            <p/>
                        </React.Fragment>
                    }
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="loadingBarBorder">
                                <div id="loadingBar1" className="loadingBar" ref={loadingBarRef}></div>
                            </div>
                        </div>
                    </div>
                    {isTimedOut &&
                        <React.Fragment>
                            <p/>
                            <div className="row">
                                <div className="col-12 error">
                                    An internal server error has occurred causing your job to time out.
                                    This is most often fixed by resubmitting your job.
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
            {goToResults && !isTimedOut && !isProcessingError &&
                <GoToResultsButton jobId={props.jobId}/>
            }
        </React.Fragment>
    )
}

export default StatusUpdates
