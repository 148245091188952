import React from 'react'

function GoToResultsButton(props) {

    const onClick = () => {
        if (process.env.REACT_APP_PRODUCTION === 'false' && process.env.REACT_APP_UAT === 'false') {
            window.location = process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/results?jobId=' + props.jobId;
        } else {
            window.location = process.env.REACT_APP_HOST + '/results?jobId=' + props.jobId;
        }
    };

    return (
        <React.Fragment>
            <p/>
            <div className="row justify-content-center">
                <div className="col-4 col-xs-2 submitButton" onClick={onClick}>
                    Go To Results
                </div>
            </div>
        </React.Fragment>
    )
}

export default GoToResultsButton
