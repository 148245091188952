import React from 'react';
import HttpService from '../../services/HttpService';
import routes from '../../resources/json/routes.json';

function AdvancedOptions(props) {

    const selectFileText = 'Select File...';
    const ACCEPTED_FILE_TYPES_INPUT_FIELD = ".gmt,.tsv";
    const acceptedFileTypes = [
        'text/plain',
        'text/tab-separated-values'
    ];

    const [selectedFile, setSelectedFile] = React.useState(selectFileText);
    const [errMessage, setErrMessage] = React.useState();
    const [loadExampleGmt, setLoadExampleGmt] = React.useState(false);

    const onManualFileUpload = e => {

        setErrMessage();

        const file = e.target.files[0];
        const fileMeta = { filename: file.name, type: file.type, size: file.size };
        setSelectedFile(fileMeta.filename);

        if (acceptedFileTypes.includes(e.target.files[0].type) || (fileMeta.type === "" && fileMeta.filename.includes('.gmt'))) {
            const reader = new FileReader()
            reader.onload = file => {
                const rawDataString = file.target.result;
                const validRes = _validateGmt(rawDataString);

                if (validRes.valid) {
                    props.customGmtSubject.next({ rawDataString, fileMeta });
                } else {
                    setErrMessage(validRes.message);
                }
            };
            reader.readAsText(e.target.files[0]);

        } else {
            setErrMessage('File type not excepted. Must be .tsv or .gmt');
        }
    };

    const _validateGmt = rawDataString => {

        const regex1 = /([a-zA-Z]|[0-9]|\/|_)+/i;
        const regex2 = /,/i;
        const regex3 = / /i;

        let message = null;
        let valid = true;
        let rowSplit;

        const rows = rawDataString.split('\n');
        for (let i = 0; i < rows.length; i++) {
            rowSplit = rows[i].split('\t');

            if (rows[i] === '') continue;
            if (!regex1.test(rowSplit[0])) {
                valid = false;
                message = 'Incorrect format Column 1 Row ' + (i+1);
                break;
            }

            for (let j = 0; j < rowSplit.length; j++) {
                if (j !== 1 && regex2.test(rowSplit[j])) {
                    valid = false;
                    message = 'Remove comma.  Column ' + (j+1) + ' Row ' + (i+1);
                    break;
                }

                if (j !==1 && regex3.test(rowSplit[j])) {
                    valid = false;
                    message = 'Remove space.  Column ' + (j+1) + ' Row ' + (i+1);
                    break;
                }
            }

            if (!valid) break;
        }

        if (valid) {
            const firstColAry = [];
            rows.forEach(row => {
                firstColAry.push(row.split('\t')[0]);
            });

            if (new Set(firstColAry).size !== firstColAry.length) {
                valid = false;
                message = 'Duplicate value found in Column 1';
            }
        }

        props.customGmtValidSubject.next(valid);
        return { valid, message };
    };

    const onClickClearSignature = () => {
        setSelectedFile(selectFileText);
        props.customGmtSubject.next(null);
        props.customGmtValidSubject.next(true);
    };

    const onClickLoadExampleGmt = () => {
        setSelectedFile('hallmark.gmt');
        props.exampleGmtLoadSubject.next(!loadExampleGmt);
        setLoadExampleGmt(!loadExampleGmt)
    };

    const onClickDownloadGmtExample = () => {
        HttpService.get(routes.server.api.root + routes.server.api.exampleGmtGet)
            .then(res => {
                const tsvFile = new Blob([res.data.exampleGmt], { type: 'text/tab-separated-values' })
                const downloadLink =  document.createElement('a')
                downloadLink.download = `example_signature.gmt`
                downloadLink.href = window.URL.createObjectURL(tsvFile)
                downloadLink.style.display = 'none'
                document.body.appendChild(downloadLink)
                downloadLink.click()
            });
    };

    return (
        <React.Fragment>
            <p/>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-5">
                    Upload custom signature (in .gmt format):
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-5" id="clearSignatureButton">
                    <span onClick={onClickClearSignature}>Clear Signature</span>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-5 col-md-5" id="customFile" lang="es" onChange={onManualFileUpload}>
                    <input type="file" className="custom-file-input" id="advInputFile" aria-describedby="fileHelp" accept={ACCEPTED_FILE_TYPES_INPUT_FIELD}/>
                    <label className="custom-file-label" id="advInputFileLabel" htmlFor="advInputFile">{selectedFile}</label>
                </div>
            </div>
            <div className="row">
                    <div className="col-xs-5 col-sm-5 col-md-3" id="loadExampleGmt">
                        <span onClick={onClickLoadExampleGmt}>Load Example (hallmark.gmt)</span>
                    </div>
                    <div className="col-xs-5 col-sm-5 col-md-2" id="downloadGmtTxtAlign">
                        <span onClick={onClickDownloadGmtExample}>Download Example</span>
                    </div>
            </div>
            <p/>
            {errMessage !== '' && errMessage !== null &&
                <div className="row">
                    <div className="col-5 error">
                        {errMessage}
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default AdvancedOptions
