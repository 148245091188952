import React from 'react'
import BannerImg from '../resources/jpg/timex-banner.jpg';

function Banner() {
    return (
        <React.Fragment>
            <div>
                <img src={BannerImg} id="banner" alt="banner.jpg"/>
            </div>
            <div className="col-12" id="bannerTitle">
                TIMEx
                <div className="col-12" id="bannerSubtext">
                    Tumor-immune microenvironment deconvolution web-portal for bulk transcriptomics using pan-cancer scRNA-seq signatures
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner
