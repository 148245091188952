import React, { useEffect } from 'react'
import { timer } from 'rxjs';
import GoToResultsButton from './GoToResultsButton';
import HttpService from '../../services/HttpService';
import routes from '../../resources/json/routes.json';


function StatusUpdatesClientDriven(props) {

    const POLL_INTERVAL_SECONDS = 10;

    const [status, setStatus] = React.useState({ status: 'Preparing data', percentComplete: 0 });
    const [goToResults, setGoToResults] = React.useState(false);
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState();
    const [isProcessingError, setIsProcessingError] = React.useState();

    const loadingBarRef = React.useRef();

    useEffect(() => {
        const subscription = timer(500, POLL_INTERVAL_SECONDS * 1000).subscribe(i => { 
            HttpService.getStatusClientDriven(routes.ms_status.api.root + routes.ms_status.api.getStatusClientDriven, { jobId: props.jobId })
                .then(res => {

                    setStatus({ status: res.data.runningStatus, percentComplete: res.data.percentComplete });
                    document.getElementById('loadingBar1').style.width = res.data.percentComplete + '%';

                    if (res.data.processingComplete || res.data.timedOut || res.data.processingError) {
                        subscription.unsubscribe();
                        setGoToResults(true);
                        setIsTimedOut(res.data.timedOut);
                        setErrorMessage(res.data.processingErrorMessage);
                        setIsProcessingError(res.data.processingError);
                    }
                });
         });
    }, [props.jobId]);

    useEffect(() => {

        if (isTimedOut || isProcessingError) {
            loadingBarRef.current.className = 'loadingBarError';
        }

    }, [isTimedOut, isProcessingError]);

    return (
        <React.Fragment>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8 col-xl-4 customBorder">
                    <div className="row justify-content-ceneter">
                        <div className="col-12">
                            Current Status: <span className="noticeNormal">{status.status}</span>
                        </div>
                    </div>
                    {(isTimedOut || isProcessingError) &&
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    Error: <span className="noticeError">{errorMessage}</span>
                                </div>
                            </div>
                            <p/>
                        </React.Fragment>
                    }
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="loadingBarBorder">
                                <div id="loadingBar1" className="loadingBar" ref={loadingBarRef}></div>
                            </div>
                        </div>
                    </div>
                    {isTimedOut &&
                        <React.Fragment>
                            <p/>
                            <div className="row">
                                <div className="col-12 error">
                                    An internal server error has occurred causing your job to time out.
                                    This is most often fixed by resubmitting your job.
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
            {goToResults && !isTimedOut && !isProcessingError &&
                <GoToResultsButton jobId={props.jobId}/>
            }
        </React.Fragment>
    )
}

export default StatusUpdatesClientDriven
