import React from 'react'

function Footer() {

    const onClickTanLab = () => {
        window.open('http://tanlab.org', '_blank');
    };

    const onClickMoffitt = () => {
        window.open('https://moffitt.org/', '_blank');
    };

    const onClickBbsr = () => {
        window.open('https://moffitt.org/research-science/divisions-and-departments/quantitative-science/biostatistics-and-bioinformatics/', '_blank');
    };

    return (
        <React.Fragment>
            <div className="spacerLg"/>
            <div className="row" style={{marginRight: '0em'}}>
                <span style={{height: '1em', width: '33.6%', backgroundColor: '#06225c'}}></span>
                <span style={{height: '1em', width: '16.6%', backgroundColor: '#4cafea'}}></span>
                <span style={{height: '1em', width: '16.6%', backgroundColor: '#c2d7ec'}}></span>
                <span style={{height: '1em', width: '16.6%', backgroundColor: '#ffffff'}}></span>
                <span style={{height: '1em', width: '16.6%', backgroundColor: '#a0cd63'}}></span>
            </div>
            <div className="spacer"/>
            <div className="row justify-content-center">
                <div className="col-12" style={{textAlign: 'center'}}>
                    TIMEx | 
                    © <span className="falseAnchor" onClick={onClickTanLab}>Tan Lab </span>
                    | <span className="falseAnchor" onClick={onClickMoffitt}>Moffitt Cancer Center </span>
                    | Application powered by <span className="falseAnchor" onClick={onClickBbsr}>BBSR</span>
                </div>
            </div>
            <div className="spacer"/>
        </React.Fragment>
    )
}

export default Footer
