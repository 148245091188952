class AgGridRowDataService {

    constructor () {
        this.rowLabels = ['Input Sample'];
    }

    createRowDataArray = rawData => {

        const output = [];
        
        Object.keys(rawData).forEach((key, j) => {
            if (key !== 'headers') {
                let row = {};
                rawData.headers.forEach((header, i) => {
                    if (this.rowLabels.includes(header)) {
                        row = { ...row, [header]: key }
                    } else {
                        row = { ...row, [header]: rawData[key][i-1] }
                    }
                });
                output.push(row);
            }
        });

        return output;
    };

    createColumnDefsArray = headers => {

        const output = [];
        headers.forEach(header => {
            if (this.rowLabels.includes(header)) {
                output.push({ headerName: header, field: header, filter: 'agTextColumnFilter', pinned: 'left', suppressMovable: true, cellStyle: { 'font-size': '12px'} });
            } else {
                output.push({ headerName: header, field: header, filter: 'agNumberColumnFilter', type: 'numericColumn', valueFormatter: params => params.data[header].toFixed(4), cellStyle: { 'font-size': '12px', color: '#b4b4b4'} });
            }
        });

        return output;
    };

}

export default new AgGridRowDataService();