import React from 'react'

function ContactUs() {
    return (
        <React.Fragment>
            <p/>
            <div className="row">
                <div className="col-2 spacerLeftSmall">
                    <b>Contact Us:</b>
                </div>
            </div>
            <div className="row">
                <div className="col-6 spacerLeftSmall">
                    Mengyu Xie: <a href="mailto: mengyu.xie2@moffitt.org">mengyu.xie2@moffitt.org</a>
                </div>
            </div>
            <div className="row">
                <div className="col-6 spacerLeftSmall">
                    Aik Choon Tan: <a href="mailto: aikchoon.tan@moffitt.org">aikchoon.tan@moffitt.org</a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactUs
