import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Plotly from 'plotly.js-dist';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function Heatmap(props) {

    const getCharsLongestYLabelAndSampleCount = () => {
        let chars = 0;
        let nSamples = -1;
        Object.keys(props.data).forEach(key => {
            ++nSamples;
            if (key.length > chars)
                chars = key.length;
        });

        return { chars, nSamples };
    };

    const rowLabels = ['Input Sample'];
    const nHeaderMinimumManualHeightOverride = 30;
    const nHeaderMinimumManualHeightOverrideDivHeight = 800;
    const minNSamplesPlotWidthIncrease = 80;
    const plotlyBaseTopPad = 100;
    const [divId] = React.useState(uuidv4());
    const divRef = React.createRef();
    const svgContainerRef = React.useRef();

    let yLabelPad = 100;
    let showScale = true;
    let hovertemplate = 'Cell Type: <b>%{y}</b><br>Sample: <b>%{x}</b></br>Score: <b>%{z:.4f}</b>';

    const dataSpecs = getCharsLongestYLabelAndSampleCount();
    const charsLongestYLabel = dataSpecs.chars;
    const nSamples = dataSpecs.nSamples;
    const nHeaders = props.data.headers.length
    charsLongestYLabel > 10 && nSamples > 10 ? yLabelPad += charsLongestYLabel : yLabelPad = 0;

    let leftBuffer = 0;
    props.data.headers.forEach(header => {
        if (header.length > leftBuffer) {
            leftBuffer = header.length;
        }
    });

    if (leftBuffer > 30) {
        leftBuffer *= 4.5;
    }

    const layout = {
        paper_bgcolor: '#ffffff',
        plot_bgcolor: '#ffffff',
        font: {
            color: '#06225c'
        },
        xaxis: { dtick: 1, showticklabels: true, side: 'top' },
        yaxis: { dtick: 1, showticklabels: true, autorange: 'reversed' },
        margin: {
            l: 150 + leftBuffer,
            r: 50,
            b: 100,
            t: plotlyBaseTopPad + yLabelPad,
            pad: 4
        }
    }

    if (nHeaders < nHeaderMinimumManualHeightOverride) {
        layout['height'] = nHeaderMinimumManualHeightOverrideDivHeight;
    }

    if (props.isNormalizedSelected) {
        hovertemplate = 'Cell Type: <b>%{y}</b><br>Sample: <b>%{x}</b></br>Score: <b>Unavailable in z-normalized view</b>'
    }

    useEffect(() => {

        const xLabels = [];
        const yLabels = [];
        let zData = [];

        props.data.headers.forEach(header => {
            if (!rowLabels.includes(header)) {
                yLabels.push(header);
            }
        });

        Object.keys(props.data).forEach((key, i) => {
            if (key !== 'headers') {
                xLabels.push(key);
            }
        });

        let min = 0;
        let max = 0;
        for (let i = 0; i < props.data[xLabels[0]].length; i++) {
            const row = [];
            for (const key of xLabels) {
                if (key !== 'headers') {
                    if (props.data[key][i] > max) {
                        max = props.data[key][i];
                    }
                    if (props.data[key][i] < min) {
                        min = props.data[key][i]
                    }
                    row.push(props.data[key][i]);
                }
            };
            zData.push(row);
        }
        
        let colorscale = [
            ['0.0', '#0606ff'],
            ['0.1', '#2f2fff'],
            ['0.2', '#6e6eff'],
            ['0.3', '#acacff'],
            ['0.4', '#eaeaff'],
            ['0.5', '#ffffff'],
            ['0.6', '#ffeaea'],
            ['0.7', '#ffacac'],
            ['0.8', '#ff6d6d'],
            ['0.9', '#ff2f2f'],
            ['1.0', '#ff0505']
        ];

        if (props.isNormalizedSelected) {
            // scale each row to the range of min, max

            const nZData = [];
            zData.forEach(dataAry => {
                const rowMin = Math.min(...dataAry);
                const rowMax = Math.max(...dataAry);

                const nScaledRow = [];
                dataAry.forEach(e => {
                    const scaledVal = ( (e - rowMin) * (max - min) / (rowMax - rowMin) ) + min;
                    nScaledRow.push(scaledVal);
                });

                nZData.push(nScaledRow);
            });

            zData = nZData;
        }

        const data = [
            {
              z: zData,
              x: xLabels,
              y: yLabels,
              type: 'heatmap',
              hoverongaps: false,
              colorscale: colorscale,
              showscale: showScale,
              zmax: max,
              zmin: min,
              hovertemplate,
              colorbar: { showticklabels: !props.isNormalizedSelected }
            }
          ];

          if (yLabels.length >= 30) {
            divRef.current.style.height = '1000px';
          } else {
              divRef.current.style.height = '500px';
          }
          
          Plotly.newPlot(divId, data, layout, { modeBarButtonsToRemove: ['toImage'] });
    });

    useEffect(() => {
        if (nSamples > 500) {
            divRef.current.style.width = '15000px';
        } else if (nSamples > 200) {
            divRef.current.style.width = '7000px';
        } else if (nSamples > 150) {
            divRef.current.style.width = '5000px';
        } else if (nSamples > minNSamplesPlotWidthIncrease) {
            divRef.current.style.width = '2000px';
        } else {
            //
        }
    }, [divRef, nSamples]);

    useEffect(() => {
        if (props.data.headers.length < nHeaderMinimumManualHeightOverride) {
            svgContainerRef.current.style.height = String(nHeaderMinimumManualHeightOverrideDivHeight) + 'px';
        }

    }, [props.data]);

    const onImgDownload = imgType => {
        Plotly.downloadImage(divId, {
            format: imgType,
            height: divRef.current.offsetHeight,
            width: divRef.current.offsetWidth,
            filename: props.baseFilename
        });
    };

    return (
        <React.Fragment>
            <div className="row justify-content-end">
                {nSamples > minNSamplesPlotWidthIncrease &&
                    <div className="col-6 attention2" style={{textAlign: 'end', fontWeight: 550}}>
                        Extended dataset. Please scroll right.
                    </div>
                }
                <div className="col-xs-4">
                    <span className="pointer" onClick={()=>onImgDownload('svg')}>
                        <img src={DownloadSVG} className="downloadIcon" alt="download-plot"/> SVG
                    </span>
                    <span className="pointer" onClick={()=>onImgDownload('png')}>
                        <img src={DownloadSVG} className="downloadIconRight" alt="download-plot"/> PNG
                    </span>
                </div>
            </div>
            <div className="col-12 svgContainer" ref={svgContainerRef} style={{overflowX: 'auto'}}>
                <div id={divId} ref={divRef}/>
            </div>
        </React.Fragment>
    )
}

export default Heatmap