import React, { useEffect } from 'react'
import routes from '../../resources/json/routes.json'
import HttpService from '../../services/HttpService';
import ResultsNavbar from './ResultsNavbar';
import StateService from '../../services/StateService';

function ViewResults(props) {

    const EXAMPLE_DATASET = 'LUAD_EXAMPLE';

    const [jobId, setJobId] = React.useState('');
    const [successGetData, setSuccessGetData] = React.useState(false);
    const [err, setErr] = React.useState({ err: false, jobStatus: 'pending', message: '' });
    const [queryStringUsed, setQueryStringUsed] = React.useState(false);
    const [loadExampleDataset, setLoadExampleDataset] = React.useState(true);
    const [customSignatureUsed, setCustomSignatureUsed] = React.useState(false);

    const jobIdRef = React.createRef();
    const jobIdBlockRef = React.createRef();
    
    useEffect(() => {
        let subscription = StateService.results$().subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (props.jobId && props.jobId !== 'TCGA') {
            onSubmit();
        }
    }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.jobId === 'TCGA') {
            jobIdBlockRef.current.style.display = 'none';
        }
        if (props.jobId && props.jobId !== 'TCGA') {
            setSuccessGetData(false);
            setJobId(props.jobId);
        }
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const delimiter = '?jobId=';
        
        if (window.location.href.includes(delimiter)) {
            const query_split = window.location.href.split(delimiter);
            const qJobId = query_split.pop();
            jobIdRef.current.value = qJobId;
            setQueryStringUsed(true);
            setJobId(qJobId);
        }
    }, [jobIdRef])

    useEffect(() => {
        if (setQueryStringUsed) {
            onSubmit();
        }
    }, [queryStringUsed]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = e => {
        setSuccessGetData(false);
        setJobId(e.target.value);
    };

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const onSubmit = () => {
        setErr({ err: false, jobStatus: 'pending', message: '' });

        if (jobId !== '' && jobId !== null && jobId !== undefined) {
            const url = routes.server.api.root + routes.server.api.resultsGet;
            HttpService.post(url, { jobId })
                .then(res => {
                    setCustomSignatureUsed(res.data.customSignatureUsed);
                    if (!res.data.customSignatureUsed && res.data.results.filteredTimex) {
                        setSuccessGetData(true);
                        StateService.results$().next(res.data.results);
                    } else if (res.data.customSignatureUsed && res.data.results.customTimex) {
                        setSuccessGetData(true);
                        res.data.results.filteredTimex = res.data.results.customTimex;
                        StateService.results$().next(res.data.results);
                    } else {
                        setErr({
                            err: true,
                            jobStatus: '',
                            message: 'Data is currently unavailable'
                        });
                    }
                })
                .catch(err => {
                    setSuccessGetData(false);
                    if (err.response.data.err && err.response.data.jobStatus === false) {
                        setErr({ 
                            err: err.response.data.err,
                            jobStatus: err.response.data.jobStatus,
                            message: 'Job is currently running' 
                        });
                    } else if ((err.response.data.err && err.response.data.jobStatus === null) ||
                               (err.response.data.err && err.response.data.jobStatus === null && err.response.data.message === 'Invalid Job Name')) {
                        setErr({ 
                            err: err.response.data.err,
                            jobStatus: err.response.data.jobStatus,
                            message: 'Invalid Job Name' 
                        });
                    } else {
                        setErr({ 
                            err: err.response.data.err,
                            jobStatus: err.response.data.jobStatus,
                            message: 'Unknown error encountered.  Please confirm your Job Name' 
                        });
                    }

                });
        }
    };

    useEffect(() => {
        onSubmit();
    }, [loadExampleDataset]); // eslint-disable-line react-hooks/exhaustive-deps

    const onLoadExampleDataset = () => {
        jobIdRef.current.value = EXAMPLE_DATASET;
        setJobId(EXAMPLE_DATASET);
        setLoadExampleDataset(!loadExampleDataset);  // used as switch to activate useEffect to call onSubmit()
    };

    return (
        <React.Fragment>
            <p/>
            <div className="row justify-content-center" >
                <div className="col-8 col-xs-5 customBorder" ref={jobIdBlockRef}>
                    <div className="row justify-content-center rowPadAlign">
                        <div className="col-12 col-xs-8 col-md-8 col-lg-8 col-xl-8">
                            <input type="text" id="jobIdTextField" ref={jobIdRef} placeholder="Enter Job Name" onChange={onChange} onKeyPress={onKeyPress}/>
                        </div>
                    </div>
                    <p/>
                    <div className="row justify-content-center rowPadAlign">
                        <div className="col-10 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 submitButton2" onClick={onSubmit}>Retrieve Results</div>
                    </div>
                    <p/>
                    <div className="row justify-content-center rowPadAlign">
                        <div className="col-10 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 submitButton2" onClick={onLoadExampleDataset}>View Example Dataset</div>
                    </div>
                </div>
            </div>
            {successGetData && 
                <React.Fragment>
                    <p/>
                    <ResultsNavbar jobId={jobId} customSignatureUsed={customSignatureUsed}/>
                </React.Fragment>
            }
            {err.err &&
                <React.Fragment>
                    <p/>
                    <div className="row justify-content-center">
                        <div className="col-5 customBorder" style={{textAlign: 'center'}}>
                            {err.jobStatus === false && 
                                <span className="attention">{err.message}</span>
                            }
                            {err.jobStatus === null && 
                                <span className="error">{err.message}</span>
                            }
                            {err.jobStatus !== false && err.jobStatus !== null && 
                                <span className="error">{err.message}</span>
                            }
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ViewResults
