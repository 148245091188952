import React from 'react'
import {AgGridReact} from 'ag-grid-react';
import cellTypesDifEst from '../../resources/json/cellTypesDifEst.json';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function CellTypesDifEst() {

    // eslint-disable-next-line
    const [gridApi, setGridApi] = React.useState(null);
    // eslint-disable-next-line
    const [gridColumnApi, setGridColumnApi] = React.useState(null);

    const rowData = () => {
        const output = [];

        cellTypesDifEst.forEach(obj => {
            output.push({ abbreviation: obj.abbreviation, cellType: obj.cellType, lineage: obj.lineage, timex: obj.timex, consensusTme: obj.consensusTme, xcell: obj.xcell, cibersort: obj.cibersort, timer: obj.timer, epic: obj.epic, mcpCounter: obj.mcpCounter, quantiSeq: obj.quantiSeq });
        });
        
        return output;
    };

    const columnDefs = () => {
        return [
            { headerName: 'Abbreviation', field: 'abbreviation', filter: 'agTextColumnFilter', width: 200, cellStyle: { 'font-size': '12px' } },
            { headerName: 'Cell Type', field: 'cellType', filter: 'agTextColumnFilter', width: 300, cellStyle: { 'font-size': '12px' } },
            { headerName: 'Lineage', field: 'lineage', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } },
            { headerName: 'TIMEx', field: 'timex', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } },
            { headerName: 'ConsensusTME', field: 'consensusTme', filter: 'agTextColumnFilter', width: 150, cellStyle: { 'font-size': '12px' } },
            { headerName: 'xCELL', field: 'xcell', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } },
            { headerName: 'Cibersort', field: 'cibersort', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } },
            { headerName: 'TIMER', field: 'timer', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } },
            { headerName: 'EPIC', field: 'epic', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } },
            { headerName: 'MCP-Counter', field: 'mcpCounter', filter: 'agTextColumnFilter', width: 150, cellStyle: { 'font-size': '12px' } },
            { headerName: 'quantTIseq', field: 'quantiSeq', filter: 'agTextColumnFilter', width: 120, cellStyle: { 'font-size': '12px' } }
        ]
    };

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onGridDownload = () => {
        const params = { fileName: 'different_cell_types_estimated_by_timex_and_other_methods.csv' };
        if (gridApi)
            gridApi.exportDataAsCsv(params);
    };

    return (
        <React.Fragment>
            <div className="row justify-content-end">
                <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" onClick={()=>onGridDownload()}>
                    <img src={DownloadSVG} className="downloadIcon" alt="download-csv"/>
                    <span style={{cursor: 'pointer'}}>CSV</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-xs-8 ag-theme-balham">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData()}
                        columnDefs={columnDefs()}
                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                        domLayout={'autoHeight'}
                    >
                    </AgGridReact>
                </div>
            </div>
            <div className="spacer"/>
        </React.Fragment>
    )
}

export default CellTypesDifEst
