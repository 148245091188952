import {BehaviorSubject} from 'rxjs'

class StateService {

    constructor() {
        this.results = new BehaviorSubject({ test: null });
    }

    results$ = () => {
        return this.results;
    }

}

export default new StateService();