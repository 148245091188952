import React from 'react'

function TcgaNotice() {
    return (
        <React.Fragment>
            <div style={{marginLeft: '-9em'}}>
                <div className="row">
                    <div className="col-xs-10 col-sm-5 offset-4" id="tcgaNoticeText">
                        <div style={{marginTop: '1.5em'}}/>
                            {process.env.REACT_APP_PRODUCTION === 'false' && process.env.REACT_APP_UAT === 'false' &&
                                <span>Pre-calculated TCGA RNA-seq data by TIMEx is available <a href={process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/tcga'}>here</a>.</span>
                            }
                            {!(process.env.REACT_APP_PRODUCTION === 'false' && process.env.REACT_APP_UAT === 'false') &&
                                <span>Pre-calculated TCGA RNA-seq data by TIMEx is available <a href={process.env.REACT_APP_HOST + '/tcga'}>here</a>.</span>
                            }
                        <div style={{marginTop: '0em'}}/>
                        <b> Please do not run TCGA data using this website.</b>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TcgaNotice
