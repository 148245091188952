import React from 'react'

function UploadInstructions() {
    return (
        <React.Fragment>
            <div className="col-12"/>
            <div className="spacer"/>
            <div style={{marginLeft: '-9em'}}>
                <div className="row">
                    <div className="col-xs-10 col-sm-5 offset-4">
                        <h5 className="instructionJustifyText">Instructions:</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-10 col-sm-5 offset-4">
                        <ol className="instructionJustifyText">
                            <li>Enter a Job Name</li>
                            <li>Select experiment modality</li>
                            <li>Select study's cancer type from the drop list</li>
                            <li>Select your expression data file from the file selector
                                <ul>
                                    <li>Or drag and drop file into the drop zone</li>
                                </ul>
                            </li>
                            <li>Push Submit</li>
                        </ol>
                    </div>
                </div>
                <p/>
                <div className="row">
                    <div className="col-xs-10 col-sm-5 offset-4">
                        <h5 className="instructionJustifyText">Notice:</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-10 col-sm-5 offset-4">
                        <ul className="instructionJustifyText">
                            <li>
                                An Email address is <b>OPTIONAL</b> and is only used to send the user a notification 
                                when their job has completed processing and is ready for viewing. We do not save your email address.
                            </li>
                            <li>
                                We do not save any files. All uploaded data is immediately deleted after the analysis. 
                                Analysis results will be deleted after two weeks.
                            </li>
                            <li>
                                {process.env.REACT_APP_PRODUCTION === 'false' && process.env.REACT_APP_UAT === 'false' &&
                                    <span>Pre-calculated TCGA RNA-seq data by TIMEx is available <a href={process.env.REACT_APP_HOST + ':' + process.env.REACT_APP_PORT + '/tcga'}>here</a>.</span>
                                }
                                {!(process.env.REACT_APP_PRODUCTION === 'false' && process.env.REACT_APP_UAT === 'false') &&
                                    <span>Pre-calculated TCGA RNA-seq data by TIMEx is available <a href={process.env.REACT_APP_HOST + '/tcga'}>here</a>.</span>
                                }
                                <div style={{marginTop: '0em'}}/>
                                <b> Please do not run TCGA data using this website.</b>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default UploadInstructions
