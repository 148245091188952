import React from 'react'

function JobSubmissionNotification(props) {

    const jobId = props.jobId;
    const email = props.email;
    const ANONYMOUS_EMAIL = props.anonymousEmail;

    return (
        <div className="row justify-content-center">
            <div className="col-8 col-xl-4 customBorder">
                <div>
                    <b>Job Name:</b> <span className="noticeNormal">{jobId}</span>
                </div>
                <div>
                    Your job has been submitted
                </div>
                {email !== ANONYMOUS_EMAIL &&
                    <div>
                        You will receive an email when it is completed
                    </div>
                }
                <div>
                    The Job Name is used to retrieve your data
                </div>
                {email !== ANONYMOUS_EMAIL &&
                    <div>
                        The Job Name will be in the the email notification
                    </div>
                }
            </div>
        </div>
    )
}

export default JobSubmissionNotification
