import React from 'react'
import {AgGridReact} from 'ag-grid-react';
import cancerTypes from '../../resources/json/cancerTypes.json';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function CancerTypes() {

    const rowData = () => {
        const output = [];
        Object.keys(cancerTypes).forEach(key => {
            output.push({ cancerType: key, tcgaStudyName: cancerTypes[key] });
        });
        
        return output;
    };

    const columnDefs = () => {
        return [
            { headerName: 'Cancer Type', field: 'cancerType', width: 80, filter: 'agTextColumnFilter', cellStyle: { 'font-size': '12px' } },
            { headerName: 'TCGA Study Name', field: 'tcgaStudyName', filter: 'agTextColumnFilter', cellStyle: { 'font-size': '12px' } }
        ]
    };

    // eslint-disable-next-line
    const [gridApi, setGridApi] = React.useState(null);

    // eslint-disable-next-line
    const [gridColumnApi, setGridColumnApi] = React.useState(null);

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    const onGridDownload = () => {
        const params = { fileName: 'cancer_types.csv' };
        if (gridApi)
            gridApi.exportDataAsCsv(params);
    };

    return (
        <React.Fragment>
            <div className="row justify-content-end">
                <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" onClick={()=>onGridDownload()}>
                    <img src={DownloadSVG} className="downloadIcon" alt="download-csv"/>
                    <span style={{cursor: 'pointer'}}>CSV</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-xs-8 ag-theme-balham">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData()}
                        columnDefs={columnDefs()}
                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                        domLayout={'autoHeight'}
                    >
                    </AgGridReact>
                </div>
            </div>
            <div className="spacer"/>
        </React.Fragment>
    )
}

export default CancerTypes
