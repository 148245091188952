import React, {useState, useEffect} from 'react'
import {AgGridReact} from 'ag-grid-react';
import Plotly from 'plotly.js-dist';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function RenderGeneCoverage(props) {

    const [plotlyDivHeight, setPlotlyDivHeight] = React.useState();

    const xaxisTitle = 'Cell Type';
    const yaxisTitle = 'Coverage';
    const plotlyDiv = 'barPlotDiv';
    const minNSamplesPlotWidthIncrease = 80;

    const [nSamples, setNSamples] = React.useState(0);
    const plotlyDivRef = React.useRef('450px');

    useEffect(() => {
        let numSamples = 0;
        Object.keys(props.results).forEach((key, i) => { numSamples++ });

        if (numSamples > 500) {
            plotlyDivRef.current.style.width = '15000px';
        } else if (numSamples > 200) {
            plotlyDivRef.current.style.width = '7000px';
        } else if (numSamples > 150) {
            plotlyDivRef.current.style.width = '5000px';
        } else if (numSamples > minNSamplesPlotWidthIncrease) {
            plotlyDivRef.current.style.width = '2000px';
        } else {
            //
        }

        setNSamples(numSamples);
    }, [plotlyDivRef, props.results]);

    useEffect(() => {
        const xLabels = [];
        const yLabels = [];

        let nSamples = 0;
        Object.keys(props.results).forEach((key, i) => { nSamples++ });

        let tickAngle = 35;
        if (nSamples > 70) {
            tickAngle = -90;
        }


        let bottomBuffer = 0;
        Object.keys(props.results).forEach(key => {
            xLabels.push(key);
            yLabels.push(props.results[key]);
            if (key.length > bottomBuffer) {
                bottomBuffer = key.length;
            }
        });

        if(bottomBuffer > 30) {
            bottomBuffer *= 4.5;
            setPlotlyDivHeight(String(450+bottomBuffer)+ 'px')
        }

        const layout = {
            paper_bgcolor: '#ffffff',
            plot_bgcolor: '#ffffff',
            xaxis: { 
                tickangle: tickAngle,
                title: { 
                    text: xaxisTitle,
                    standoff: 200 
                } 
            },
            yaxis: { gridcolor: '#7d7d7d', title: { text: yaxisTitle } },
            font: {
                color: '#06225c'
            },
            legend: {
                bordercolor: '#ffffff',
                bgcolor: '#ffffff',
                font: {
                    color: '#06225c'
                }
            },
            margin: {
                l: 50,
                r: 50,
                b: 100 + bottomBuffer,
                t: 10,
                pad: 1
            }
        }

        const data = [{
            x: xLabels,
            y: yLabels,
            type: 'bar'
        }];

        Plotly.newPlot(plotlyDiv, data, layout, { modeBarButtonsToRemove: ['toImage'] });
    }, [props.results]);

    const rowData = () => {
        const output = [];
        Object.keys(props.results).forEach(key => {
            output.push({ celltype: key, coverage: props.results[key] });
        });

        return output;
    };

    const columnDefs = () => {
        const output = [
            { headerName: xaxisTitle, field: 'celltype', filter: 'agTextColumnFilter', cellStyle: { 'font-size': '12px' } },
            { headerName: yaxisTitle, field: 'coverage', filter: 'agNumberColumnFilter', type: 'numericColumn', valueFormatter: params => params.data.coverage.toFixed(4), cellStyle: { 'font-size': '12px', color: '#b4b4b4'} }
        ];
        
        return output;
    };

    // eslint-disable-next-line
    const [gridApi, setGridApi] = useState(null);

    // eslint-disable-next-line
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    const onGridDownload = () => {
        const params = { fileName: props.baseFilename + '.csv' };
        if (gridApi)
            gridApi.exportDataAsCsv(params);
    };

    const onImgDownload = imgType => {
        Plotly.downloadImage(plotlyDiv, {
            format: imgType,
            height: plotlyDivRef.current.offsetHeight,
            width: plotlyDivRef.current.offsetWidth,
            filename: props.baseFilename
        });
    };

    return (
        <React.Fragment>
            <div className="row justify-content-end">
                {nSamples > minNSamplesPlotWidthIncrease &&
                    <div className="col-6 attention2" style={{textAlign: 'end', fontWeight: 550}}>
                        Extended dataset. Please scroll right.
                    </div>
                }
                <div className="col-xs-4 svgPngMarginRight">
                    <span className="pointer" onClick={()=>onImgDownload('svg')}>
                        <img src={DownloadSVG} className="downloadIcon" alt="download-plot"/> SVG
                    </span>
                    <span className="pointer" onClick={()=>onImgDownload('png')}>
                        <img src={DownloadSVG} className="downloadIconRight" alt="download-plot"/> PNG
                    </span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12" style={{overflowX: 'auto'}}>
                    <div id="barPlotDiv" ref={plotlyDivRef} style={{height: plotlyDivHeight}}/>
                </div>
            </div>
            <div className="spacer"/>
            <div className="row justify-content-center">
                <div className="col-5 lineDivider"/>
            </div>
            <div className="spacer"/>
            <div className="row justify-content-end">
                <div className="col-5" onClick={()=>onGridDownload()}>
                    <img src={DownloadSVG} className="downloadIcon" alt="download-csv"/>
                    CSV
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4 ag-theme-balham">
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData()}
                        columnDefs={columnDefs()}
                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                        domLayout={'autoHeight'}
                    >
                    </AgGridReact>
                </div>
            </div>
            <div className="spacer"/>
        </React.Fragment>
    )
}

export default RenderGeneCoverage
