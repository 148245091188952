import React, { useEffect } from 'react'
import HttpService from '../services/HttpService';
import routes from '../resources/json/routes.json';
import cancerTypes from '../resources/json/cancerTypes.json';
import ViewResults from './results/ViewResults';

function Tcga() {

    const [data, setData] = React.useState();
    const [selectedDataset, setSelectedDataset] = React.useState('TCGA');

    useEffect(() => {
        const url = routes.server.api.root + routes.server.api.tcgaListGet;
        let tcgaMap = {};
        HttpService.get(url)
            .then(res => {
                res.data.results.forEach(obj => {
                    tcgaMap = { ...tcgaMap, [obj.cancerType]: { value: obj.jobId, fullName: cancerTypes[obj.cancerType] } };
                });
                setData(tcgaMap);
            });
    }, []);

    const onChange = e => {
        setSelectedDataset(e.target.value);
    };

    return (
        <React.Fragment>
            <p/>
            {data &&
                <React.Fragment>
                    <div className="row justify-content-center">
                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-3">
                            <div className="input-group">
                                <select className="form-control" onChange={onChange}>
                                    <option key="0" value="Select Cohort">Select Cohort</option>
                                    {Object.keys(data).map((key, i) => {
                                        return <option key={i+1} value={data[key].value}>{data[key].fullName} ({key})</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <ViewResults jobId={selectedDataset}/>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Tcga
