import React, { useEffect } from 'react'
import HttpService from '../../services/HttpService';
import routes from '../../resources/json/routes.json';
import DownloadSVG from '../../resources/svg/file-download-solid.svg';

function RenderComparativeAnalysis(props) {

    const [pngList, setPngList] = React.useState();
    const [pngResponse, setPngResponse] = React.useState();
    const [selectedPng, setSelectedPng] = React.useState();
    const [selectedPngFilename, setSelectedPngFilename] = React.useState();
    const [err, setErr] = React.useState({ err: false, message: null });
    const [pngsExist, setPngsExist] = React.useState(true);

    const PNG_BASE64_PREFIX = 'data:image/png;base64,';

    useEffect(() => {
        const url = routes.server.api.root + routes.server.api.pngGet;
        HttpService.post(url, { jobId: props.jobId })
            .then(res => {
                const pngs = [];
                Object.keys(res.data).forEach(key => {
                    if (key.includes('.png')) {
                        pngs.push(key);
                    }
                });
                if (pngs.length > 0) {
                    setPngList(pngs);
                    setSelectedPng(PNG_BASE64_PREFIX + res.data[pngs[0]]);
                    setSelectedPngFilename(pngs[0]);
                    setPngResponse(res.data);
                } else {
                    setPngsExist(false);
                }
            })
            .catch(err => {
                setErr(err.response.data);
            });
    }, [props.jobId]);

    const onChange = e => {
        setSelectedPngFilename(e.target.value);
        setSelectedPng(PNG_BASE64_PREFIX + pngResponse[e.target.value]);
    };

    const onDownload = () => {
        const link = document.createElement('a');
        link.href = selectedPng;
        link.download = selectedPngFilename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <React.Fragment>
            {pngList && pngsExist &&
                <React.Fragment>
                    <div className="row justify-content-center">
                        <div className="form-group col-3" onChange={onChange}>
                            <label htmlFor="plotSelect">Select Plot</label>
                            <select className="form-control" id="plotSelect">
                                {
                                    pngList.map((png, i) => {
                                        return <option key={i}>{png}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-3">
                            <img src={DownloadSVG} className="downloadIcon" alt="Download" onClick={onDownload}/>
                            PNG
                        </div>
                    </div>
                </React.Fragment>
            }
            {selectedPng && pngsExist &&
                <div className="row justify-content-center">
                    <img className="col-8" id="pngImage" src={selectedPng} alt="png" />
                </div>
            }
            {!pngsExist &&
                <div className="row justify-content-center">
                    <div className="col-6 error" style={{textAlign: 'center'}}>Comparative analyses plots are unavailable</div>
                </div>
            }
            {err.err &&
                <div className="row justify-content-center">
                    <div className="col-3" style={{ textAlign: 'center' }}>
                        <span className="error">{err.message}</span>
                    </div>
                </div>
            }
            <div className="spacer"/>
        </React.Fragment>
    )
}

export default RenderComparativeAnalysis
