import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import './App.css';
//import Landing from './components/Landing';
import Banner from './components/Banner';
import Navbar from './components/Navbar';
import Upload from './components/Upload';
import ViewResults from './components/results/ViewResults';
import Tcga from './components/Tcga';
import About from './components/About';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <React.Fragment>
        <Banner/>
        <BrowserRouter>
          <Navbar/>
          {/*<Route path="(/|/home|/landing)" component={Landing} />*/}
          <Route path="(/|/home|/landing|/upload)" component={Upload} />
          <Route path="/results" component={ViewResults}/>
          <Route path="/tcga" component={Tcga}/>
          <Route path="/about" component={About}/>
          <Route path="/contact" component={ContactUs}/> 
        </BrowserRouter>
        <Footer/>
    </React.Fragment>
  );
}

export default App;
