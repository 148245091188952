import React, { useEffect } from 'react'
import JobSubmissionNotification from './JobSubmissionNotification';
import GoToResultsButton from './GoToResultsButton';
import io from 'socket.io-client';
import mockStatusUpdates from '../../resources/json/mockStatusUpdates.json';
import mockStatusUpdatesCustomGmt from '../../resources/json/mockStatusUpdatesCustomGmt.json';

function ExampleUploadPanel(props) {

    const [status, setStatus] = React.useState('Preparing data');
    const [goToResults, setGoToResults] = React.useState(false);
    const loadingBarRef = React.createRef();

    useEffect(() => {
        let URL = null;
        
        URL = process.env.REACT_APP_STATUS_MS_HOST + ':' + process.env.REACT_APP_STATUS_MS_PORT;
        const socket = io(URL, { query: { jobId: props.jobId, route: 'mock', customSignature: props.customSignature } });

        let count = 0;

        socket.on('connect', () => {});
        socket.on('mockRes', res => {
            if (!props.customSignature) {
                setStatus(mockStatusUpdates.statuses[count++]);
            } else {
                setStatus(mockStatusUpdatesCustomGmt.statuses[count++]);
            }
            document.getElementById('loadingBar1').style.width = res.percentComplete + '%';

            if (res.percentComplete === 100) {
                socket.disconnect();
                setGoToResults(true);
            }

        });

    }, [props.jobId, props.customSignature]);

    return (
        <React.Fragment>
            <p/>
            <JobSubmissionNotification jobId={props.jobId} email={props.email} anonymousEmail={props.anonymousEmail}/>
            <p/>
            <div className="row justify-content-center">
                <div className="col-8 col-xl-4 customBorder">
                    <div className="row justify-content-ceneter">
                        <div className="col-12">
                            Current Status: <span className="affirmative">{status}</span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="loadingBarBorder">
                                <div id="loadingBar1" className="loadingBar" ref={loadingBarRef}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {goToResults &&
                <GoToResultsButton jobId={props.jobId}/>
            }
        </React.Fragment>
    )
}

export default ExampleUploadPanel
