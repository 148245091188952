import axios from 'axios';

class HttpService {
    
    constructor () {
        this.baseUrl = null;
        if (process.env.REACT_APP_PRODUCTION === 'true') {
            this.baseUrl = process.env.REACT_APP_FLASK_HOST + '/' + process.env.REACT_APP_FLASK_PROXY_SUFFIX;
        } else {
            this.baseUrl = process.env.REACT_APP_FLASK_HOST + ':' + process.env.REACT_APP_FLASK_PORT;
        }
    }

    get = url => {
        return axios.get(this.baseUrl + url);
    };

    post = (url, body) => {
        return axios.post(this.baseUrl + url, body);
    };

    put = (url, body, config) => {
        return axios.put(this.baseUrl + url, body, config);
    };

    getStatic = url => {
        let URL = null;
        if (process.env.REACT_APP_PRODUCTION === 'true') {
            URL = process.env.REACT_APP_PROXY_HOST + url;
        } else {
            URL = process.env.REACT_APP_PROXY_HOST + ':' + process.env.REACT_APP_PROXY_PORT + url;
        }
        return axios.get(URL);
    };

    getStatusClientDriven = (url, body) => {
        let URL = null;
        if (process.env.REACT_APP_PRODUCTION === 'false' && process.env.REACT_APP_UAT === 'false') {
            URL = process.env.REACT_APP_STATUS_MS_HOST + ':' + process.env.REACT_APP_STATUS_MS_PORT + url;
        } else {
            URL = process.env.REACT_APP_STATUS_MS_HOST + ':' + process.env.REACT_APP_STATUS_MS_PORT + '/' + process.env.REACT_APP_STATUS_MS_PROXY_SUFFIX + url;
        }
        return axios.post(URL, { jobId: body.jobId });
    };
}

export default new HttpService();