import React, { useEffect } from 'react'
import routes from '../resources/json/routes.json';
import {Link, useHistory} from 'react-router-dom';

function Navbar() {

    let history = useHistory();

    const links = {
        upload: { name: 'Upload', route:routes.client.upload, ref: React.createRef() },
        results: { name: 'Results', route: routes.client.results, ref: React.createRef() },
        tcga: { name: 'TCGA Results', route: routes.client.tcga, ref: React.createRef() },
        about: { name: 'About', route: routes.client.about, ref: React.createRef() },
        contact: { name: 'Contact Us', route: routes.client.contact, ref: React.createRef() },
        termsConditions: { name: 'Terms & Conditions', route: routes.client.termsConditions, ref: React.createRef() }
    };

    // eslint-disable-next-line 
    const [selectedLink, setSelectedLink] = React.useState(links.upload.route);

    useEffect(() => {
        onClickLink(history.location.pathname);
    });

    const onClickLink = e => {   
        Object.keys(links).forEach(key => {
            if (e === links[key].route) {
                links[key].ref.current.className = 'navbarLinkSelected';
                setSelectedLink(links[key].route);
            } else if (['/', '/home', '/landing'].includes(e)) {
                links.upload.ref.current.className = 'navbarLinkSelected';
            } else {
                links[key].ref.current.className = 'navbarLink';
            }
        });

    };

    const onClickTermsConditions = () => {
        window.open(links.termsConditions.route, '_blank');
    };

    return (
        <div className="row navbarMain">
            <div className="col-1x">
                <Link className="navbarTitle" ref={links.upload.ref} to="/" onClick={()=>onClickLink(links.upload.route)}><b>TIMEx</b> |</Link>
            </div>
            <div className="col-1x">
                <Link className="navbarLink" ref={links.results.ref} to={links.results.route} onClick={()=>onClickLink(links.results.route)}>{links.results.name}</Link>
            </div>
            <div className="col-1x navgap">
                <Link className="navbarLink" ref={links.tcga.ref} to={links.tcga.route} onClick={()=>onClickLink(links.tcga.route)}>{links.tcga.name}</Link>
            </div>
            <div className="col-1x spacerLeft"> </div>
            <div className="col-1x">
                <Link className="navbarLink navAboutSpacer" ref={links.about.ref} to={links.about.route} onClick={()=>onClickLink(links.about.route)}>{links.about.name}</Link>
            </div>
            <div className="col-1x">
                <Link className="navbarLink" ref={links.contact.ref} to={links.contact.route} onClick={()=>onClickLink(links.contact.route)}>{links.contact.name}</Link>
            </div>
            <div className="navbarLink" style={{marginLeft: '-0.5em'}} ref={links.termsConditions.ref} onClick={onClickTermsConditions}>
                {links.termsConditions.name}
            </div>
        </div>
    )
}

export default Navbar
