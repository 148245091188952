import {mean, std} from 'mathjs';

class ZNormalizeService {

    constructor () {
        this.ignoreHeaders = ['Input Sample', 'headers'];
    }

    normalizeForHeatmap = data => {

        const keys = [];

        Object.keys(data).forEach(key => {
            if (!this.ignoreHeaders.includes(key)) {
                keys.push(key);
            }
        });

        const normalizedRows = new Array(data[keys[0]].length);

        let stdev = 0;
        let meann = 0;

        for (let i = 0; i < normalizedRows.length; i++) {
            
            const row = [];

            Object.keys(data).forEach(key => {
                if (!this.ignoreHeaders.includes(key)) {
                    row.push(data[key][i]);
                }
            });

            stdev = std(row);
            meann = mean(row);

            const tNormalizedRow = [];
            for (const e of row) {
                tNormalizedRow.push((e - meann) / stdev);
            }
            normalizedRows[i] = tNormalizedRow;
        }

        let tKey;
        let normalizedData = {};
        for (let i = 0; i < normalizedRows[0].length; i++) {
            const tRow = [];
            tKey = keys[i]; 
            normalizedRows.forEach((e, j) => {
                 tRow.push(e[i]);
            });

            normalizedData = { ...normalizedData, [tKey]: tRow };

        } 

        let headerKey = '';
        Object.keys(data).forEach(key => {
            if (!keys.includes(key)) {
                headerKey = key;
            }
        });

        normalizedData = { ...normalizedData, [headerKey]: data[headerKey] }

        return normalizedData;
    }

    normalizeForAgGrid = data => {

        const normalizedData = new Array(data.length);
        let stdev = 0;
        let meann = 0;

        data.forEach((obj, i) => {
            this.ignoreHeaders.forEach(header => {
                if (obj[header]) {
                    normalizedData[i] = { ...normalizedData[i], [header]: obj[header] };
                }
            });
        });
    
        Object.keys(data[0]).forEach((key, i) => {
            if (!this.ignoreHeaders.includes(key)) {
                
                const row = [];

                for (const obj of data) {
                    row.push(obj[key])
                }

                stdev = std(row);
                meann = mean(row);

                row.forEach((e, i) => {
                    normalizedData[i] = { ...normalizedData[i], [key]: (e - meann)/stdev };
                });
                
            }
        });

        return normalizedData;
    }
}

export default new ZNormalizeService();